.header {
    background: #0079c1;
}

.mapDiv {
    height: 100%;
    width: 100%;
}

.app-container {
    height: 100%;
    width: 100%;
}

a.bridges_link:hover{
    cursor: pointer;
    text-decoration: underline;
}


.chat-msg-textarea.chat-msg-textarea--multiLine{
    width: inherit;
}
.chakra-stack.chat-user-details-wrapper ul{

     left: 0px; 
     top: unset;
    bottom: 10px;

}
.chat-msg-textarea__suggestions {
    top: 0px;
    bttom: 160px;
}
.mobile-filter-button {
    z-index: 100;
    position: absolute;
    left: 10px;
    margin-top: 10px;
    /* top:10px; */
    height: 46px;
    border-width: 2px;
    border-color: #616A72;
    width: 113px;
    background-color: #FFFFFF;
}

.custom-button {
    z-index: 100;
    position: absolute;
    right: 10px;
    margin-top: 10px;
    /* top:10px; */
    height: 40px;
    border-width: 2px;
    border-color: #616A72;
    width: 40px;
    background-color: #FFFFFF;
    /* position: ; */
}

.draw-button {
    z-index: 100;
    position: absolute;
    right: 10px;
    margin-top: 10px;
    /* top:10px; */
    height: 46px;
    border-width: 2px;
    border-color: #616A72;
    width: 113px;
    background-color: #FFFFFF;
    /* position: ; */
}

.alert-button {
    z-index: 100;
    position: absolute;
    right: 140px;
    margin-top: 10px;
    /* top:10px; */
    height: 46px;
    border-width: 2px;
    border-color: #616A72;
    width: 113px;
    background-color: #FFFFFF;
    /* position: ; */
}


.apply-banner {
    display: flex;
    position: absolute;
    width: -webkit-fill-available;
    height: 75px;
    justify-content: space-between;
    align-items: center;
}

.edit-draw-button {
    z-index: 100;
    position: absolute;
    right: 10px;
    margin-top: 10px;
    /* top:10px; */
    height: 46px;
    border-width: 2px;
    border-color: #616A72;
    /* width: 113px; */
    background-color: #000;
}

.edit-box {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 10px;
    height: -webkit-fill-available;
    /* height: 75px; */
    justify-content: flex-start;
    align-items: center;
}

.edit-list-container {
    width: 227px;
    max-height: 364px;
    left: 1193px;
    margin-top: 65px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
}

.edit-list-event-button {
    /* position: relative; */
    color: #FFFFFF;
    width: 193px;
    height: 40px;
    /* left: 15px; */
    background: #000000!important;
    border-radius: 6px;
}

.event-type-list{
    height: 40px;
    background: #000000!important;
    width: 193px;
    color: #FFFFFF;
    font-weight: 700;
    border-radius: 6px;
}

select.chakra-select.event-type-list option {
    background: #000;
    color: #fff;
}

.event-type-list + .chakra-select__icon-wrapper {
    color: white;
  }

.toast-banner {
    /* position: absolute; */
    width: 567px;
    height: 74px;
    /* left: 437px;
top: 88px; */
    align-items: center;
    background: #8CB832;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.18);
    border-radius: 8px;
}

.esri-popup__inline-actions-container,
header.esri-popup__header,
.esri-popup__footer {
    display: none;
}

.esri-popup__content {
    margin: 0px;
    display: none;
}

.esri-popup__content,
.esri-ui .esri-popup,
.esri-view-width-medium .esri-popup__main-container {
    border-radius: 6px;
    background-color: transparent;
    /* max-width: 75px; */
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    display: flex;
}

.esri-widget__table tr th,
.esri-widget__table tr td {
    background-color: white;
}

.esri-view-height-less-than-medium .esri-popup__main-container,
.esri-view-width-xlarge .esri-popup__main-container {
    background-color: transparent;
    max-width: 375px;
}

.esri-expand__panel>div {
    background-image: url(assets/images/BaseMapThumb.png);
    background-position: center;
    padding: 30px;
    /* margin:5px; */
    border-radius: 5px;
}

.esri-expand__panel {
    padding: 0%!important;
}

.esri-component.esri-expand.esri-widget.esri-expand--auto {
    border-radius: 5px;
    background: transparent;
    box-shadow: none;
}

.esri-basemap-gallery__item-container {
    flex-direction: row;
    justify-content: space-between;
    /* height: inherit; */
    margin: 10px;
}

.esri-basemap-gallery__item {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    flex: 0.3;
    justify-content: space-between;
    text-align: center;
    padding: 0px;
    height: fit-content;
}

.esri-basemap-gallery__item-thumbnail {
    width: 45px;
    height: 45px;
    border-radius: 4px;
    border-width: 2px;
    border-color: black;
    border-style: solid;
}

.esri-view .esri-basemap-gallery {
    max-width: 400px;
}

.esri-basemap-gallery__item-title,
.esri-basemap-gallery__item:first-child {
    word-break: break-word;
    padding: 0px;
    margin: 0px;
}

.esri-basemap-gallery.esri-widget.esri-widget--panel-height-only {
    /* width: 356px; */
    /* height: 122px;  */
    border-radius: 6px;
    padding-left: 0px;
    padding-right: 0px;
}

.esri-basemap-gallery__item:hover,
.esri-basemap-gallery__item:focus {
    border-inline-start-color: transparent;
}

.esri-basemap-gallery__item-title {
    font-size: 12px;
    font-weight: 500;
    color: black;
}

.esri-expand__container.esri-expand__container--expanded {
    position: relative !important;
    ;
    display: flex;
    width: inherit;
    background: transparent !important;
}

.damage-rating-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 6px;
    flex: 1;
}

.blackInfo-container {
    display: flex;
    background-color: #000;
    padding: 8px;
    border-radius: 6px;
}

.rating-button-text {
    color: white;
    font-size: 10px;
    font-weight: 600;
}

.rating-info-icon {
    align-self: center;
}

.damage-rating-button {
    flex: 1;
    display: flex;
}

.assessment-gallery-thumb {
    width: 189px;
    height: 126px;
    border-radius: 4px 4px 0 0;
    margin-right: 18px;
}

.lg-react-element {
    display: flex
}

div#lg-backdrop-2 {
    opacity: 0.7;
}

.table-title svg {
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: top;
}

.map-sidebar-wrap {
    width: 100%;
}

.push-notification-title {
    font-size: x-small;
    display: flex;
    justify-content: space-between;
    color: #014361;
    
  }
  
  .push-notification-text {
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    margin: 0;
    padding-bottom: 1rem;
    letter-spacing: 1px;
  }
  
  .notification {
    position: absolute;
    top:0;
    right:0;
    margin:2rem;
    background-color: #e5f6fd;
    padding: 0 2rem 0 2rem;
    width: 20rem;
    border-radius: 1rem;
  }
  
  .close-button {
    background-color: transparent;
    border:0;
    cursor: pointer;
  }
  
  .notification-banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 12px 16px;
    background: #3e5fcd;
    font-size: 16px;
    color: #ffffff;
  }
  
  .notification-banner-link {
    margin-left: 4px;
    color: #ffffff;
  }