@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

.logoDiv{
    display: flex;
    width: 100px;
    height: 100px;
    padding: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
    background-image: linear-gradient(349deg, #289e00, #018601 52%, #095000);
}
.logoImage{
  display: inline-block;
  max-width: 100%;
}

.sideBarLogo{
  border-radius: 50%;
  background-image: linear-gradient(349deg, #289e00, #018601 52%, #095000);
}

#fill-4{
  fill: #018601;
}

#chakra-modal-delete_modal {
  padding: 20px 15px 10px;
}

.new-chat-icon {
  background-color: #E2E2E2;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 8px;
}

.new-chat-icon:hover {
 cursor: pointer;
}

span.chat-count {
  background: #17A2B8;
  border-radius: 30px;
  min-width: 20px;
  min-height: 20px;
  position: unset;
  max-width: max-content;
  height: max-content;
}
.username-message-wrap p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: -0.24px;
  color: #B8B8B8;
}
.user-detail-wrap .chakra-avatar {
  background: transparent;
  border: 1px solid #5e4c23;
  color: #5e4c23;
  width: 40px;
  height: 40px;
}
.chat-header {
  padding: 10px 20px 40px;
}
.chat-timer{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 21px;
  letter-spacing: -0.26px;
  margin: 0 0 -10px 0 !important;
  min-width: 50px;
  text-align: center;
}
.chat-header .chakra-heading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #545454;
}
.chat-msg{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.26px;
  word-break: break-word !important;
}
.chakra-stack.chat-msg-wrapper {
  margin: 0;
  border-top: 1px solid #E8E8E8;
  border-radius: 0;
  height: 100vh;
}
.chakra-stack.chat-user-details-wrapper .chakra-avatar{
  background: transparent;
  border: 1px solid #5e4c23;
  color: #5e4c23;
}
.chakra-stack.chat-user-details-wrapper .chakra-heading {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: -0.26px;
  color: #545454;
}
.chakra-textarea.chat-msg-textarea{
  background: #F4F4F4;
  border-radius: 50px;
  min-height: 40px;
  max-height: max-content;
  padding: 10px 20px;
  margin-right: 7px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
}
.chakra-input__right-element.send-btn-wrapper {
  width: auto;
  height: auto;
  padding: 0;
  position: unset;
}
.chakra-button.chat-send-btn {
  background: #F4F4F4;
  border-radius: 40px;
}
.chakra-button.chat-send-btn svg{
  fill: #000000;
}
.chakra-stack.chat-user-details-wrapper{
  margin: 0;
}
.chakra-stack.msg-time-wrap.receiver-wrap {
  word-break:break-word;
  direction:column;
  background:#E8E8E8;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  padding:var(--chakra-space-6);
  border-top-right-radius:var(--chakra-radii-2xl);
  border-bottom-right-radius:var(--chakra-radii-2xl);
  border-bottom-left-radius:var(--chakra-radii-2xl);
  color:#545454;
 }
 
 .receiver-wrap p{
  color: #545454;
 }
.msg-time-wrap.sender-wrap {
  background: #545454;
  padding: var(--chakra-space-6);
  border-top-left-radius: var(--chakra-radii-2xl);
  border-bottom-right-radius: var(--chakra-radii-2xl);
  border-bottom-left-radius: var(--chakra-radii-2xl);
}

.message-option{
  position: absolute!important;
  top: 7px;
  right: 10px;
  height: 15px!important;
} 
.message-option-view{
  /* display: none; Hide by default */

background-color:var(--chakra-colors-gray-100);;
top: 7px;

position: absolute;
right: 3%;
width: 97px;
/* height: 66px; */
border-radius: 6px;
}




.sender-wrap .chat-timer{
  color: #fff;
}



.chakra-stack.msg-time-wrap{
  align-items: flex-end;
  flex-wrap: nowrap;
  flex-direction: unset;
}
.chakra-stack.chat-user-wrap {
  box-shadow: none;
}

div.styles-module_content__2jwZj {
  height: 600px;
}
.chakra-stack.slider-content-wrapper {
  position: relative;
}
.slider-content {
  position: fixed;
  top: 50%;
  width: 100%;
  height: 100%;
  right: 150px;
  z-index: 9;
  transform: translate(0%, -50%);
  max-width: 300px;
  z-index: 999999;
  display: block !important;
  flex-direction: none;
  height: 600px;
  margin: 0 0 0 44px !important;
  background-color: #44474A;
  padding: 44px 40px;
}
img.styles-module_image__2hdkJ {
  height: auto;
  /* TO DO: adds sidebar space for image detail */
  /* height: 600px; */ 
  /* width: calc(100% - 300px); */
  width: 100%;
  object-fit: cover;
  margin-left: 0;
  margin-right: auto;
}
.slider-content p.chakra-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 11px 0 !important;
  width: 100%;
}
.showuserlist {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
.chakra-stack.chat-user-details-wrapper ul {
  list-style: none;
  min-width: 172px;
  width:100%;
  min-height: 90px;
  max-height: 500px;
  height: auto;
  overflow-y: auto;
  background: #FFFFFF;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 11%);
  border-radius: 10px;
  padding: 0;
  position: absolute;
  z-index: 5;
  left: 30px;
  top: 30px;
}
.Suggestion-list{
  position: relative!important;
    left: 0px!important;
 top: 0px!important;
 border-width: 0.5px;

}
.Suggestion-list li:hover{
background-color:var(--chakra-colors-gray-100) ;

}

.chakra-stack.chat-user-details-wrapper li {
  padding: 10px 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: #757575;
  height: auto;
}
.chakra-stack.chat-user-details-wrapper li  span.chakra-avatar {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  box-shadow: none;
}
.chakra-stack.chat-user-details-wrapper li  span.chakra-avatar .chakra-avatar__initials{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 10.119px;
  line-height: 14px;
  letter-spacing: -0.154762px;
  color: #545454;
}
.bridgedetails-wrap {
  padding: 12px 16px;
  border-top: none;
  border-radius: 0 0 8px 8px;
}
@media only screen and (max-width:1400px){
 div.chat-msg-box-wrapper{
    max-width: 99%;
  }
}

.styles-module_slide__1zrfk.react-simple-image-viewer__slide {
  background-color: #44474A;
}