ul.lst-kix_aol0lbh6x718-6 {
    list-style-type: none
 }

 ul.lst-kix_aol0lbh6x718-5 {
    list-style-type: none
 }

 ul.lst-kix_aol0lbh6x718-8 {
    list-style-type: none
 }

 ul.lst-kix_aol0lbh6x718-7 {
    list-style-type: none
 }

 .lst-kix_7o4bekijd4rt-8>li:before {
    content: "\0025a0  "
 }

 .lst-kix_lxo0h7tt4fun-8>li:before {
    content: "\0025a0  "
 }

 .lst-kix_lxo0h7tt4fun-7>li:before {
    content: "\0025cb  "
 }

 .lst-kix_lxo0h7tt4fun-6>li:before {
    content: "\0025cf  "
 }

 .lst-kix_lxo0h7tt4fun-4>li:before {
    content: "\0025cb  "
 }

 .lst-kix_lxo0h7tt4fun-5>li:before {
    content: "\0025a0  "
 }

 ul.lst-kix_7o4bekijd4rt-1 {
    list-style-type: none
 }

 .lst-kix_aol0lbh6x718-4>li:before {
    content: "\0025cb  "
 }

 ul.lst-kix_7o4bekijd4rt-0 {
    list-style-type: none
 }

 .lst-kix_aol0lbh6x718-5>li:before {
    content: "\0025a0  "
 }

 .lst-kix_lxo0h7tt4fun-0>li:before {
    content: "\0025cf  "
 }

 .lst-kix_lxo0h7tt4fun-1>li:before {
    content: "\0025cb  "
 }

 .lst-kix_lxo0h7tt4fun-2>li:before {
    content: "\0025a0  "
 }

 .lst-kix_lxo0h7tt4fun-3>li:before {
    content: "\0025cf  "
 }

 .lst-kix_aol0lbh6x718-3>li:before {
    content: "\0025cf  "
 }

 .lst-kix_aol0lbh6x718-2>li:before {
    content: "\0025a0  "
 }

 .lst-kix_aol0lbh6x718-0>li:before {
    content: "\0025cf  "
 }

 .lst-kix_aol0lbh6x718-1>li:before {
    content: "\0025cb  "
 }

 ul.lst-kix_7o4bekijd4rt-5 {
    list-style-type: none
 }

 .lst-kix_aol0lbh6x718-6>li:before {
    content: "\0025cf  "
 }

 ul.lst-kix_7o4bekijd4rt-4 {
    list-style-type: none
 }

 ul.lst-kix_7o4bekijd4rt-3 {
    list-style-type: none
 }

 ul.lst-kix_7o4bekijd4rt-2 {
    list-style-type: none
 }

 ul.lst-kix_7o4bekijd4rt-8 {
    list-style-type: none
 }

 ul.lst-kix_7o4bekijd4rt-7 {
    list-style-type: none
 }

 .lst-kix_aol0lbh6x718-7>li:before {
    content: "\0025cb  "
 }

 ul.lst-kix_7o4bekijd4rt-6 {
    list-style-type: none
 }

 .lst-kix_aol0lbh6x718-8>li:before {
    content: "\0025a0  "
 }

 .lst-kix_7o4bekijd4rt-2>li:before {
    content: "\0025a0  "
 }

 .lst-kix_7o4bekijd4rt-3>li:before {
    content: "\0025cf  "
 }

 .lst-kix_7o4bekijd4rt-0>li:before {
    content: "\0025cf  "
 }

 .lst-kix_7o4bekijd4rt-4>li:before {
    content: "\0025cb  "
 }

 .lst-kix_7o4bekijd4rt-6>li:before {
    content: "\0025cf  "
 }

 .lst-kix_7o4bekijd4rt-7>li:before {
    content: "\0025cb  "
 }

 .lst-kix_7o4bekijd4rt-5>li:before {
    content: "\0025a0  "
 }

 li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
 }

 ul.lst-kix_lxo0h7tt4fun-3 {
    list-style-type: none
 }

 ul.lst-kix_lxo0h7tt4fun-2 {
    list-style-type: none
 }

 ul.lst-kix_lxo0h7tt4fun-5 {
    list-style-type: none
 }

 ul.lst-kix_aol0lbh6x718-0 {
    list-style-type: none
 }

 ul.lst-kix_lxo0h7tt4fun-4 {
    list-style-type: none
 }

 ul.lst-kix_lxo0h7tt4fun-7 {
    list-style-type: none
 }

 ul.lst-kix_aol0lbh6x718-2 {
    list-style-type: none
 }

 ul.lst-kix_lxo0h7tt4fun-6 {
    list-style-type: none
 }

 ul.lst-kix_aol0lbh6x718-1 {
    list-style-type: none
 }

 ul.lst-kix_aol0lbh6x718-4 {
    list-style-type: none
 }

 ul.lst-kix_lxo0h7tt4fun-8 {
    list-style-type: none
 }

 ul.lst-kix_aol0lbh6x718-3 {
    list-style-type: none
 }

 .lst-kix_7o4bekijd4rt-1>li:before {
    content: "\0025cb  "
 }

 ul.lst-kix_lxo0h7tt4fun-1 {
    list-style-type: none
 }

 ul.lst-kix_lxo0h7tt4fun-0 {
    list-style-type: none
 }

 ol {
    margin: 0;
    padding: 0
 }

 table td,
 table th {
    padding: 0
 }

 .c2 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-right-width: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    border-left-width: 0pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 0pt;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
 }

 .c1 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-right-width: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    border-top-style: solid;
    margin-left: 54pt;
    border-bottom-width: 0pt;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt
 }

 .c9 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    .header-eight: 11pt
 }

 .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
 }

 .c6 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
 }

 .c8 {
    background-color: #ffffff;
    padding: 72pt 72pt 72pt 72pt
 }

 .c7 {
    padding: 0;
    margin: 0
 }

 .c3 {
    font-weight: 700
 }

 .c4 {
    font-style: italic
 }

 .c5 {
    color: #4472c4
 }

 .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
 }

 .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
 }

 li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
 }

 p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
 }

 .header-1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
 }

 .header-2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
 }

 .header-3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
 }

 .header-4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
 }

 .header-5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
 }

 .header-6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
 }